/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import favicon16 from '../static/favicon/favicon-16x16.png'
import favicon32 from '../static/favicon/favicon-32x32.png'
import favicon from '../static/favicon/favicon.ico'

type MetaType =
  | {
    name: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: any
    property?: undefined
  }
  | {
    property: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: any
    name?: undefined
  }

type HeadComponentProps = {
  title: string
  description?: string
  lang?: string
  meta?: MetaType[]
}

export default function HeadComponent({
  title,
  description = '',
  meta = [],
  lang = 'en',
}: HeadComponentProps): ReactElement {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={
        [
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: `${favicon16}`,
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: `${favicon32}`,
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '48x48',
            href: `${favicon}`,
          },
        ]
      }
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
      ].concat(meta)}
    />
  )
}
